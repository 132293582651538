import React, { useMemo } from "react";
import ProductsContext from "../../../../context/products-context";
import * as styles from './listing.module.css';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useContext } from "react";
import Price from "../../price/price";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "gatsby";
import Badge from "../../../material/badge/badge";

export default function ProductListing({ shopifyId, eager }) {
    const { products } = useContext(ProductsContext);
    const product = products.find(sub => sub.shopifyId == shopifyId);

    const [variant, setVariant] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (product == null) return;
        const defaultVariant = product.variants[0];
        setVariant(defaultVariant);
        const defaultImages = product.images.map(image => getImage(image.gatsbyImageData));
        const defaultImage = defaultImages[0];
        setImage(defaultImage);
    }, [product]);

    const isOutOfStock = useMemo(() => product?.tracksInventory && product?.totalInventory === 0, [product]);

    return product == null || variant == null ? null : <Link to={`/products/${product.handle}`} className={styles.productListing}>
        <GatsbyImage loading={eager ? 'eager' : 'lazy'} imgClassName="rounded-lg" image={image} className={styles.thumbnail} />
        <div className={styles.info}>
            <div className={styles.title}>
                { product.title }
                { isOutOfStock && <Badge className='ml-2 rounded-full px-3 text-sm'>Out of Stock</Badge> }
            </div>

            <div className={styles.price}>
                <Price flip isSimplePrice={true} price={variant.price} comparisonPrice={variant.compareAtPrice} />
            </div>
        </div>
    </Link>
}