import React from 'react';
import { useContext, useMemo } from 'react';
import * as styles from './banner.module.css';
import CollectionsContext from '../../../context/collections-context';
import { Link } from 'gatsby';
import ResponsiveContext from '../../../context/responsive-context';

export function CollectionBanner({ shopifyId, className }) {
    const { isMobile } = useContext(ResponsiveContext);
    const { collections } = useContext(CollectionsContext);
    const collection = collections.find(sub => sub.shopifyId == shopifyId);

    const meta = useMemo(() => collection == null ? {} : Object.fromEntries(
        collection.metafields.map(({ key, value }) => [key, value])
    ), [collection]);

    const thumbnail = <img style={{ height: '6rem' }} className='rounded-lg' src={meta.banner_image_url}></img>;

    return collection == null ? null : <div className={`${styles.banner} ${className}`}>
        { isMobile && meta.banner_image_url != null && thumbnail }
        <span
            className='text-xl p-1 mb-3 rounded'
            style={{ borderBottom: `4px solid ${meta.accent_color}` }}
        >
            { collection.title }
        </span>
        <div className={styles.content}>
            { !isMobile && meta.banner_image_url != null && thumbnail }
            <div className={styles.oneLiner}>
                { `${meta.one_liner.split(' ').slice(0, 25).join(' ')}...` }
                { meta.landing_page_url != null && <Link className='ml-2' style={{ color: meta.accent_color }} to={meta.landing_page_url}>read more</Link> }
            </div>
        </div>
    </div>
}