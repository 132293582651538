import React, { useContext } from 'react';
import CollectionsContext from '../../../context/collections-context';
import ProductListing from '../product/listing/listing';

export default function CollectionProductListings({ className, shopifyId }) {

    const { collections } = useContext(CollectionsContext);
    const collection = collections.find(sub => sub.shopifyId == shopifyId);

    return collection == null ? null : <div className={`grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 ${className}`}>
        { collection.products.map((sub, i) => <ProductListing eager={i < 2} key={sub.shopifyId} shopifyId={sub.shopifyId} />) }
    </div>

}