import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useEffect, useState } from 'react';

const COLLECTIONS_QUERY = graphql`
query {
    allShopifyCollection {
        nodes {
            metafields {
                value
                key
            }
            shopifyId
            title
            products {
                shopifyId
            }
            image {
                gatsbyImageData
                originalSrc
            }
            handle
            descriptionHtml
            description
        }
    }
}`;

const DEFAULT = { collections: [] };
const CollectionsContext = createContext(DEFAULT);

export function CollectionsContextProvider({ children }) {

    const shopifyCollectionsQuery = useStaticQuery(COLLECTIONS_QUERY);
    const [collections, setCollections] = useState([]);

    useEffect(() => {
        setCollections(shopifyCollectionsQuery.allShopifyCollection.nodes);
    }, [shopifyCollectionsQuery.allShopifyCollection.nodes]);
    
    const value = { ...DEFAULT, collections };
    return (
        <CollectionsContext.Provider value={value}>
            {children}
        </CollectionsContext.Provider>
    );

}

export default CollectionsContext;