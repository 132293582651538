import * as React from 'react';
import { CollectionBanner } from '../components/commerce/collection/banner';

import { Layout } from '../components/layout/layout';

import * as styles from './collection-page.module.css';
import { ProductsContextProvider } from '../context/products-context';
import { CollectionsContextProvider } from '../context/collections-context';
import CollectionProductListings from '../components/commerce/collection/product-listings';

export default function CollectionPage({ pageContext }) {
    const collection = pageContext;

    return <Layout>
        <CollectionsContextProvider>
            <ProductsContextProvider>
                <CollectionBanner className={styles.hero} shopifyId={collection.shopifyId} />
                <CollectionProductListings shopifyId={collection.shopifyId} className={styles.productGrid} />
            </ProductsContextProvider>
        </CollectionsContextProvider>
    </Layout>
}